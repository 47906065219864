import { getServerHelpers } from "../../helpers";
import { registerInAppRevenueCatCurrentEntitlements } from "../../internal-utils/license-utils";
import { validateToken } from "../../internal-utils/server-auth";

export * from "./license__getLicenseNoAuth";
export * from "./license__getLicensesNoAuth";
export * from "./license__addTrialLicense";
export * from "./license__getSubscription";

export async function license__server__inAppLicenseCelebrate(p: { accountId: string; plan: string }) {
  // SERVER_ONLY_TOGGLE
  const account = await getServerHelpers().appOllieFirestoreV2.Account.getDoc(p.accountId);
  if (!account) {
    return;
  }
  await getServerHelpers().channels.goodNewsChannel(
    `=== :moneybag::moneybag::moneybag: ===
In App Subscription Started
Account: ${account.lastName}, ${account.firstName}
Plan: ${p.plan}

    `
  );
  // SERVER_ONLY_TOGGLE
}

// -------------
// pass through apis
// -------------
export async function license__server__registerInAppRevenueCatCurrentEntitlements(
  p: Parameters<typeof registerInAppRevenueCatCurrentEntitlements>[0]
) {
  await registerInAppRevenueCatCurrentEntitlements(p);
}
license__server__registerInAppRevenueCatCurrentEntitlements.auth = async (req: any) => {
  await validateToken(req);
};

// i18n certified - complete
